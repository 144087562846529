<template>
  <div :class="classes.root">
    <UiLabel
      v-if="label"
      :class="classes.label"
      :for="inputId"
      :variant="errors?.length ? 'error' : 'default'"
    >
      {{ label }}
    </UiLabel>

    <div class="relative">
      <span
        v-if="slots['prepend-inner']"
        :class="cn('start-xs absolute inset-y-0 flex cursor-text items-center', { 'opacity-50': disabled })"
        @click="inputRef?.focus()"
      >
        <slot name="prepend-inner" />
      </span>

      <span
        v-if="slots['append']"
        :class="cn('absolute inset-y-0 end-0 flex cursor-text items-center', { 'opacity-50': disabled })"
      >
        <slot name="append" />
      </span>

      <input
        v-bind="$attrs"
        :id="inputId"
        :type="type"
        :name="name"
        :value="modelValue"
        :class="classes.input"
        :disabled="disabled"
        @input="emitUpdateModel"
        :placeholder="placeholder"
        ref="inputRef"
      />
    </div>

    <ul v-auto-animate="autoAnimateFade" :class="classes.details">
      <li v-for="(hint, index) in fixedHints" v-text="hint" :key="index" :class="classes.hint" />
      <li v-for="(error, index) in fixedErrors" :key="index" :class="classes.errors">
        <slot v-bind="{ error }" name="errors">
          {{ error }}
        </slot>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { type VariantProps, cva } from "class-variance-authority"
import { type ClassValue } from "clsx"

export const inputCommonClasses = {
  root: cn("group flex flex-col leading-none"),
  details: cn("gap-xxxs flex flex-col text-sm leading-4"),
}

export const inputRootVariants = cva(inputCommonClasses.root, {
  variants: {
    variant: { default: "", error: "" },
    size: { sm: "", lg: "" },
  },
})

export type UiInputProps = {
  variant?: VariantProps<typeof inputRootVariants>["variant"]
  size?: VariantProps<typeof inputVariants>["size"]
  type?: string
  name?: string
  label?: string | boolean
  modelValue?: string | number | null
  hints?: string[]
  errors?: string | string[]
  hideDetails?: boolean
  placeholder?: string
  disabled?: boolean
  classes?: {
    root?: ClassValue
    label?: ClassValue
    input?: ClassValue
    errors?: ClassValue
    hint?: ClassValue
    details?: ClassValue
    prepend?: ClassValue
  }
}

export const inputVariants = cva(
  [
    "flex w-full rounded-sm border border-input bg-transparent ring-offset-background placeholder:text-black/50 placeholder:font-light",
    "file:border-0 file:bg-transparent file:text-sm file:font-medium",
    "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary disabled:cursor-not-allowed disabled:opacity-50",
  ],
  {
    variants: {
      variant: {
        default: "",
        error: "border-destructive focus-visible:ring-destructive",
      },
      size: {
        default: "h-10 text-base px-3 py-2 placeholder:text-sm",
        sm: "h-8 text-sm",
        lg: "h-12 text-lg",
        adaptive:
          "md:h-12 md:px-4 md:py-3 h-9 text-sm placeholder:text-sm md:text-base px-3 py-2 md:placeholder:text-base",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)
</script>

<script setup lang="ts">
const slots = useSlots()
const instance = getCurrentInstance()
const inputRef = ref<null | HTMLInputElement>(null)

const inputId = `input-${instance?.uid}`

const emit = defineEmits(["update:modelValue"])
const props = defineProps<UiInputProps>()

const fixedHints = computed(() => clearEmptyArrayValues(props.hints))
const fixedErrors = computed(() => clearEmptyArrayValues(toArray(props.errors)))

const classes = computed(() => {
  const fixedVariant = props.errors?.length ? "error" : props.variant

  return {
    root: cn(inputRootVariants({ variant: fixedVariant, size: props.size, class: props.classes?.root })),
    label: cn("mt-xxxs font-light", props.classes?.label),
    input: cn(inputVariants({ variant: fixedVariant, size: props.size, class: props.classes?.input }), {
      "ps-mx": slots["prepend-inner"],
      "pe-mx": slots["append"],
    }),
    errors: cn("pt-xxxs text-destructive text-sm", props.classes?.errors),
    hint: cn("pt-xxxs text-black/50", props.classes?.hint),
    details: cn(inputCommonClasses.details, props.classes?.details),
  }
})

function emitUpdateModel(event: any) {
  return emit("update:modelValue", event.target?.value)
}
</script>

<style scoped lang="scss"></style>
